var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "page" },
    [
      _c(
        "page-header",
        {
          staticClass: "mb-xs",
          attrs: {
            "show-back-icon": "",
            title: _vm.currentProject.name + " / " + _vm.currentPage.name,
            "show-status": "",
            status: _vm.currentPage.ispublic
          },
          on: {
            back: function($event) {
              return _vm.$router.go(-1)
            }
          }
        },
        [
          _c(
            "template",
            { slot: "extra" },
            [
              _c(
                "a-dropdown",
                [
                  _c(
                    "a-menu",
                    { attrs: { slot: "overlay" }, slot: "overlay" },
                    [
                      _c(
                        "a-menu-item",
                        { key: "deleteCode" },
                        [
                          _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                placement: "left",
                                title: _vm.$t(
                                  "Are you sure to delete this code"
                                ),
                                "ok-text": _vm.$t("Yes"),
                                "cancel-text": _vm.$t("No")
                              },
                              on: {
                                confirm: function() {
                                  return _vm.deleteCode()
                                }
                              }
                            },
                            [
                              _c("a-icon", { attrs: { type: "delete" } }),
                              _c("span", [
                                _vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-menu-item",
                        {
                          key: "handleCopyCodeOpening",
                          on: { click: _vm.handleCopyCodeOpening }
                        },
                        [
                          _c("a-icon", { attrs: { type: "copy" } }),
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.$t("Copy")) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "a-menu-item",
                        { key: "handleDuplicateCode" },
                        [
                          _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                placement: "bottomRight",
                                title: _vm.$t(
                                  "Would you like to duplicate this code？"
                                ),
                                "ok-text": _vm.$t("Yes"),
                                "cancel-text": _vm.$t("No")
                              },
                              on: { confirm: _vm.duplicateCode }
                            },
                            [
                              _c("a-icon", { attrs: { type: "copy" } }),
                              _c("span", [
                                _vm._v(" " + _vm._s(_vm.$t("Duplicate")) + " ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-menu-item",
                        {
                          key: "handleMoveCodeOpening",
                          on: { click: _vm.handleMoveCodeOpening }
                        },
                        [
                          _c("a-icon", { attrs: { type: "drag" } }),
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.$t("Move")) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    [
                      _vm._v(_vm._s(_vm.$t("Actions"))),
                      _c("a-icon", { attrs: { type: "down" } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _vm.code && _vm.code.error
        ? _c("div", { style: { color: "#F5222D", fontSize: "20px" } }, [
            _c("span", { staticClass: "mr-md" }, [
              _vm._v(_vm._s(_vm.$t("Error")) + ":")
            ]),
            _c("span", [_vm._v(_vm._s(_vm.code.error))])
          ])
        : _vm._e(),
      _vm.code
        ? _c("code-compose", {
            attrs: { type: "edit-code", code: _vm.code, versions: _vm.versions }
          })
        : _vm._e(),
      _vm.choosePageModalVisible
        ? _c("choose-page-modal", {
            attrs: {
              "action-type": _vm.pageChoosingActionType,
              visible: _vm.choosePageModalVisible,
              projects: _vm.projectsAndPages
            },
            on: {
              copy: _vm.handleCopyCodes,
              move: _vm.handleMoveCodes,
              cancel: function($event) {
                _vm.choosePageModalVisible = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }