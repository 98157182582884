//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import PageHeader from '@/components/tools/PageHeaderCustom.vue';

export default {
  components: {
    PageHeader,
    CodeCompose: () => import('@/components/spec/code-compose/Compose'),
    ChoosePageModal: () => import('../codes/modal/ChoosePageModal.vue')
  },
  data() {
    return {
      code: null,
      versions: [],
      loading: false,
      copying: false,
      choosePageModalVisible: false,
      pageChoosingActionType: ''
    };
  },
  computed: {
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('page', ['pages', 'pageById']),
    ...mapGetters('project', ['projectById', 'projectsAndPages']),

    pageId() {
      return this.$route.params.pageId;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    codeId() {
      return this.$route.params.codeId;
    },
    currentPage() {
      return this.pageById(this.pageId) || {};
    },
    currentProject() {
      return this.projectById(this.projectId) || {};
    }
  },
  created() {
    this.initialData();
  },
  beforeRouteLeave(to, from, next) {
    const that = this;
    if (this.$ls.get('confirm-leave')) {
      this.$ls.remove('confirm-leave');
      return next();
    }
    if (to.name !== 'code-detail') {
      return this.$confirm({
        title: that.$t('Are you sure to leave this code detail?'),
        content: that.$t('The changed content will not be save.'),
        cancelText: that.$t('No, cancel'),
        okText: that.$t('Ok'),
        okType: 'primary',
        onOk() {
          next();
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
        width: 433
      });
    }
  },
  methods: {
    ...mapActions('project', ['getProjectsAndPagesByCompany']),
    ...mapActions('page', ['getPageById']),
    async initialData() {
      try {
        this.loading = true;
        const [data, versions] = await Promise.all([
          this.$s.api.page.getCodeById(this.pageId, this.codeId),
          this.$s.api.code.getVersions({ where: { code_id: this.codeId }, order: 'created DESC' })
        ]);
        this.getPageById({ projectId: this.projectId, pageId: this.pageId });
        this.getProjectsAndPagesByCompany(this.userInfo.company_id);
        this.code = data;
        this.versions = versions;
      } catch (error) {
        this.$notification.error({
          message: this.$tc('NotFoundCodeById', this.codeId)
        });
        this.$router.push(`/projects/${this.projectId}/pages/${this.pageId}/codes`);
      } finally {
        this.loading = false;
      }
    },
    async duplicateCode() {
      try {
        this.copying = true;
        this.$ls.set('confirm-leave', true);
        await this.$s.api.page.createCodes(this.pageId, {
          ...this.code,
          title: `${this.code.title}-Copy`,
          status: 'DRAFT',
          jan_codes: null,
          order_by: null
        });
        this.$notification.success({
          message: this.$t('DuplicateSuccess')
        });
        this.$router.push(`/projects/${this.projectId}/pages/${this.pageId}/codes`);
      } catch (error) {
        console.error(error);
      } finally {
        this.copying = false;
      }
    },
    async deleteCode() {
      try {
        this.loading = true;
        this.$ls.set('confirm-leave', true);
        await this.$s.api.page.deleteCodeDraftById(this.pageId, this.codeId);
        this.$notification.success({
          message: this.$t('DeletedSuccess')
        });
        this.$router.push(`/projects/${this.projectId}/pages/${this.pageId}/codes`);
      } catch (error) {
        this.$notification.error({
          message: this.$t('Error'),
          description: error.message,
          duration: 3
        });
      } finally {
        this.loading = false;
      }
    },
    async handleCopyCodes({ selectedPageId, resolve, reject }) {
      try {
        await this.$s.api.page.copyCodeToAnotherPage(selectedPageId, [this.code.id]);
        this.$notification.success({
          message: this.$t('Copy code to page successfully', {
            name: this.currentPage.name
          })
        });
        resolve(1);
        this.choosePageModalVisible = false;
      } catch (error) {
        this.$notification.error({
          message: this.$t('Error'),
          description: error.message,
          duration: 3
        });
        reject(error);
      }
    },
    async handleMoveCodes({ selectedPageId, resolve, reject }) {
      try {
        await this.$s.api.page.moveCodeToAnotherPage(selectedPageId, [this.code.id]);
        this.$notification.success({
          message: this.$t('Move code to page successfully', {
            name: this.currentPage.name
          })
        });
        resolve(1);
        this.choosePageModalVisible = false;
        this.$ls.set('confirm-leave', true);
        this.$router.push(`/projects/${this.projectId}/pages/${this.pageId}/codes`);
      } catch (error) {
        this.$notification.error({
          message: this.$t('Error'),
          description: error.message,
          duration: 3
        });
        reject(error);
      }
    },
    handleCopyCodeOpening() {
      this.choosePageModalVisible = true;
      this.pageChoosingActionType = 'copy';
    },
    handleMoveCodeOpening() {
      this.choosePageModalVisible = true;
      this.pageChoosingActionType = 'move';
    }
  }
};
